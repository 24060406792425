.languageBrowser {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.search {
    width: 100%;
}

.contentItem {
    padding: 4px;
    margin-right: 4px;
    margin-bottom: 6px;
    border-radius: 10px;
    transition: border 0.1s, color 0.1s;
    border: 1px solid grey;
    color: grey;
    user-select: none;
}

    .contentItem > * {
        color: grey;
        transition: color 0.1s;
        user-select: none;
    }

    .contentItem:hover {
        border: 1px solid darkgray;
    }

    .contentItem:hover, .contentItem:hover > * {
        cursor: pointer;
        color: darkgray;
    }


.checked {
    border: 1px solid dodgerblue;
}

    .checked, .checked > * {
        color: dodgerblue;
    }

.checked:hover {
    border: 1px solid deepskyblue;
}

    .checked:hover, .checked:hover > * {
        color: deepskyblue;
    }