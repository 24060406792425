.hygiaEdit {
    position: relative;
    width: 100%;
    background-color: #eee;
    border-radius: 10px;
    padding: 10px;

    animation-name: InHygiaEdit;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
}

.hygiaLoader {
    border-radius: 10px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 4;
}

.divider {
    margin-top: 2px!important;
}

.body {
    width: 100%;
}

.btn_clipboard {
    margin: 10px!important;
    background-color: #cfdde4!important;
    margin-left: 10px!important;
    min-width: 25%!important;
}

    .btn_clipboard:hover {
        background-color: #7da0b0!important;
    }

.tokenWrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.btn {
    margin: 15px 0!important;
    background-color: #cfdde4!important;
}

    .btn:hover {
        background-color: #7da0b0!important;
    }

.actionWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

    .actionWrapper > * {
        margin-left: 4px!important;
        margin-right: 4px!important;
    }

@keyframes InHygiaEdit {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}